<template lang="pug">
.account-settings
  FcwProfileNav.account-settings__header(
    :user-name="user?.firstname"
    :home-route="homepage"
    :links="navbarLinks"
    @logout="logout"
  )
  .account-settings__content
    .account-settings__contentWrapper(ref="contentWrapperRef")
      NuxtPage(
        :transition="{ name: 'slide-left', mode: 'out-in', duration: 200 }"
      )
  FcwFooter(minimal)
</template>

<style lang="stylus">
.account-settings
  display flex
  flex-direction column
  min-height 100vh
  background var(--color--neutral--light-4)

.account-settings__header
  position fixed
  z-index 5
  width 100%

.account-settings__content
  display flex
  flex-direction column
  position relative
  height 100%
  width 100%
  overflow hidden
  margin 0 auto
  color var(--color--neutral--dark-4)
  padding rem(128) rem(64) rem(56)
  min-height 90vh

  +media-down('sm')
    padding 0
    padding-top rem(96)

.account-settings__contentWrapper
  height 100%
</style>

<script setup lang="ts">
import type { FcwProfileNavLink } from '@/components/organisms/FcwProfileNav.vue';

const { t } = useClientI18n();
const clientStore = useClientStore();
const { homepage, clientState } = storeToRefs(clientStore);

const authStore = useAuthStore();
const userStore = useUserStore();
const profileClientStore = useProfileStore();
const { profileClientState } = storeToRefs(profileClientStore);

const user = computed(() => userStore.user);

const hasDiscoverServices = computed(
  () => profileClientState.value.discoverServices.length > 0
);

const navbarLinks = computed<FcwProfileNavLink[]>(() => [
  ...(hasDiscoverServices.value
    ? [
        {
          title: t('nav__how_it_works'),
          location: { name: 'profile-discover' },
        },
      ]
    : []),
]);

async function logout(): Promise<void> {
  authStore.logout();

  const redirectionRoute = clientState.value.modules.profile
    ? { name: 'profile-auth' }
    : { name: 'account-settings-auth' };

  await navigateTo(redirectionRoute);
}

const contentWrapperRef = ref();
const router = useRouter();

// Scroll to the top of the nested page after each route navigation
router.afterEach(() => {
  setTimeout(() => {
    contentWrapperRef.value?.scroll({ top: 0 });
    // 200ms is the duration of the page transition, defined in transitions.styl
    // Adding 50 extra ms to handle the diff between CSS transition delay clock and JS one
  }, 250);
});
</script>
